@import "variables";
@import "ui/mixins";

// Core CSS
@import "ui/utilities";
@import "ui/type";
@import "ui/button";
@import "ui/tables-responsive";

// Components
@import "ui/badge";
@import "ui/blockquote";
@import "ui/box";
@import "ui/callout";
@import "ui/call-to-action";
@import "ui/card-blog";
@import "ui/card-box";
@import "ui/card-form";
@import "ui/card-icon";
@import "ui/card-image";
@import "ui/card-number";
@import "ui/card-portfolio";
@import "ui/card-product";
@import "ui/card-profile";
@import "ui/cover";
@import "ui/feature-callout";
@import "ui/forms";
@import "ui/hero";
@import "ui/hover";
@import "ui/hover-basic";
@import "ui/hover-link";
@import "ui/icon";
@import "ui/icon-social";
@import "ui/images";
@import "ui/list";
@import "ui/loader";
@import "ui/pricing-tables";
@import "ui/ribbon";
@import "ui/sash";
@import "ui/testimonials";
@import "ui/timeline";

.number-card-v1 {
    cursor: pointer;
}

.partner-table-icon {
    margin-right: 10px;
    cursor: pointer;
}
.company-table-icon {
    margin-right: 10px;
    cursor: pointer;
}

// Search
.search-slt {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #55595c;
    padding: 20px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    height: calc(3rem + 2px) !important;
    border-radius: 0;
}
.search-btn {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    height: calc(3rem + 2px) !important;
    border-radius: 0;
    background-color: #01BCD4 !important;
    height: 40px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #ffffff;
    border: none;
    font-weight: 600;
}
.search-box {
    // border: 1px solid red;
    // width: 700px;
    height: 12px;
    max-width: none;
}
.search-box input {
    width: auto;
    border: 1px solid #d4d0d0;
}

.page-dashboard .searchpage {
    margin-top: 40px;
}
.searchpage .profile-card-v2 {
    text-align: left;
}
.jobsearchfilter .ant-select {
    display: block;
}
.jobsearchfilter {
    border-right: 1px solid #d9d9d9;
    padding-right: 10px;
}
.candidate-details img {
    height: 100px;
    width: 100px;
}
.jobsearchfilter .ant-select-selection--single {
    height: 36px;
    padding: 3px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
}
.list-inline-item .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    height: 40px;
}
.list-inline-item .ant-input-group.ant-input-group-compact > *:last-child {
    border-radius: 50px;
    z-index: 1;
}
.list-inline-item .ant-select-selection--single {
    padding: 0px 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
}
.list-inline-item .ant-btn {
    color: #fff;
    font-size: 15px;
    background-color: #01BCD4 !important;   //CHANGED THE SEARCH BUTTON COLOR TO HEADER COLOR IN ADMIN LOGIN 
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    width: 120px;
    margin-left: -45px;
    border-radius: 50px;
    height: 42px;
    z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
    height: 40px;
    padding-right: 25px;
}

.filterresultwrapper img {
    height: 75px;
    width: 75px;
}
// ************JOB POSTING STARTS************
.ant-modal-confirm-body {
    text-align: center !important;
}
.ant-modal-confirm-body > .anticon {
    display: none;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0;
}

.post-steps .ant-steps-item-title::after {
    display: none;
}
.post-steps .ant-steps-item-container {
    outline: none;
    padding: 10px;
}
.post-steps .ant-steps-item-process .ant-steps-i tem-icon {
    background: #4fc752;
    border-color: transparent;
    float: right;
}
.post-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #cbcbcc;
    border-color: #cbcbcc;
    float: right;
}
.post-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #ffffff;
}
.post-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    color: #d80075;
}
.post-steps .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 0px;
    border: 1px #cbcbcc solid;
    border-radius: 1px;
}
.post-steps .ant-steps-item-active {
    border: 2px solid #d80075 !important;
}
.demo-style-steps .steps-content {
    margin-top: 16px;
    border: none;
    border-radius: 6px;
    background-color: transparent !important;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
.profile-card-v2 .demo-style-steps .steps-content {
    border: none !important;
}
.preference__box {
    padding-bottom: 12px;
    width: 175px;
}
.ant-tag-checkable-checked {
    background-color: #d80075;
    width: inherit;
    height: 88px;
    padding-top: 10px;
}
.ant-tag {
    white-space: break-spaces;
}

// ************JOB POSTING ENDS ************

// ************JOB SEARCH BY ADMIN IN JOBS POSTED  STARTS ************
.JobItem {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    background-color: #fff;
    margin: 20px 0;
    border-left: solid 5px purple;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }
  .JobItem img,
  .article .profile-card-v2 img {
    width: 70% !important;
    padding: 20px 0;
    text-align: center;
    max-height: 120px;
    box-shadow: none;
    border-radius: 0;
  }
  .JobItem__JobDesc {
    text-align: left;
    height: 70px;
    font-size: 14px;
    // margin-bottom: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    // line-height: 1.5;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
  }
  .JobItem__Bookmark--purple {
    background: rgba(128, 0, 128, 0.3);
    padding: 6px 4px;
    border-radius: 4px;
  }


// ************JOB SEARCH BY ADMIN IN JOBS POSTED  ENDS ************
.partner-table-company-name {
    color: blue;
    cursor: pointer;
}
//*************REPORT TABLE STARTS***************//

.event-posted-table {
    cursor: pointer;
}

.report-table-icon {
    cursor: pointer;
}
.report-table-header{
    text-align: center !important;
    font-size: 20px;
    font-weight: bold;
    
}
.export-icon-report {
    font-size: 16px;
    vertical-align: middle;
    margin-top: 5px;
    cursor: pointer;
  }
.report-page-tooltip {
    position: relative;
    margin-left: 1010px;
  }
//*************REPORT TABLE ENDS*****************//

//**************Login Form***********************//

.form-v1-container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 12px 15px #760FAC29;
opacity: 1;
width:85%;
height: 500px;
.form-v1-container--title{
    font: normal normal 349 30px/40px Helvetica;
    /* letter-spacing: 0px; */
    color: #12054D;
    opacity: 1;
}
.icon-btn-v2{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #760FAC29;
    border-radius: 22px;
   height: 50px;
   text-align: left;
   .btn-text{
    text-align: left;
    font: normal normal 300 17px/30px Helvetica;
    color: #707070;
    text-transform: capitalize;
   }
}
.form-v1{
.ant-input{
    // background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #760FAC29;
border-radius: 22px;
opacity: 1;
}
.ant-input-lg{
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
}
.btn-cta{
background: transparent radial-gradient(closest-side at 50% 50%, #711BBE 0%, #6A19B6 18%, #13064E 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #760FAC29;
border-radius: 22px;
border-color: #760FAC29;
height: 50px;

}
.additional-info{
    font-family: proxima-soft, sans-serif;
    a{
    text-align: center;
    font: normal normal normal 16px/0px;
    
    color: #000000;
    }
}
}
//**************Login Form Ends**********************//

  .contractdesc_table {
    max-height: 350px !important;
    content:"width=device-width, initial-scale=1, shrink-to-fit=no"
  }
  .ant-upload-drag-icon{
    color: #1890ff;
    font-size: 24px;
    margin-bottom: 4px;
  }
  .uploaded-contract-text{
      text-decoration:  underline;
  }